import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTModalCard = _resolveComponent("KTModalCard")!
  const _component_KTUpgradePlanModal = _resolveComponent("KTUpgradePlanModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KTModalCard, {
      title: "Upgrade Plan Modal Example",
      description: "Click on the below buttons to launch <br/>a upgrade plan example.",
      image: "media/illustrations/sketchy-1/8.png",
      "button-text": "Upgrade Plan",
      "modal-id": "kt_modal_upgrade_plan"
    }),
    _createVNode(_component_KTUpgradePlanModal)
  ], 64))
}