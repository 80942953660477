
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "upgrade-plan-modal",
  components: {},
  setup() {
    const current = ref<"month" | "annual">("month");

    const selected = ref("Startup");

    const plans = [
      {
        title: "Startup",
        subTitle: "Best for startups",
        description: "Optimal for 10+ team size and new startup",
        priceMonth: "39",
        priceAnnual: "399",
        default: true,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true,
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true,
          },
          {
            title: "Analytics Module",
            supported: true,
          },
          {
            title: "Finance Module",
            supported: false,
          },
          {
            title: "Accounting Module",
            supported: false,
          },
          {
            title: "Network Platform",
            supported: false,
          },
          {
            title: "Unlimited Cloud Space",
            supported: false,
          },
        ],
      },

      {
        title: "Advanced",
        subTitle: "Best for 100+ team size",
        description: "Optimal for 100+ team size and grown company",
        priceMonth: "339",
        priceAnnual: "3399",
        default: false,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true,
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true,
          },
          {
            title: "Analytics Module",
            supported: true,
          },
          {
            title: "Finance Module",
            supported: true,
          },
          {
            title: "Accounting Module",
            supported: true,
          },
          {
            title: "Network Platform",
            supported: false,
          },
          {
            title: "Unlimited Cloud Space",
            supported: false,
          },
        ],
      },

      {
        title: "Enterprise",
        subTitle: "Best value for 1000+ team",
        description: "Optimal for 1000+ team and enterpise",
        priceMonth: "999",
        priceAnnual: "9999",
        label: "Most popular",
        default: false,
        custom: false,
        features: [
          {
            title: "Up to 10 Active Users",
            supported: true,
          },
          {
            title: "Up to 30 Project Integrations",
            supported: true,
          },
          {
            title: "Analytics Module",
            supported: true,
          },
          {
            title: "Finance Module",
            supported: true,
          },
          {
            title: "Accounting Module",
            supported: true,
          },
          {
            title: "Network Platform",
            supported: true,
          },
          {
            title: "Unlimited Cloud Space",
            supported: true,
          },
        ],
      },

      {
        title: "Custom",
        subTitle: "Requet a custom license",
        default: false,
        custom: true,
      },
    ];

    return {
      plans,
      current,
      selected,
    };
  },
});
